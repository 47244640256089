(function ($) {
  var Sage = {
    'common': {
      init: function () {
        $('#menu__container').hide();
        $('.menu-overlay').hide();

        $('.hamburger').on('click', function () {
          $('#menu__container').fadeToggle(100);
        });

        $('.hamburger-mobile').on('click', function () {
          $('#menu__container').fadeToggle(100);
        });

        $('.hamburger--mobile--blog').on('click', function () {
          $('#menu__container').fadeToggle(100);
        });

        $('#menu__container').on('click', function () {
          $(this).fadeOut(100);
        });

        $('a[href*=#]').on('click', function (e) {
          e.preventDefault();
          $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
          }, 500, 'linear');
        });

        Vue.component('price-share', {
          props: ['share', 'sum'],
          template: '<div class="col-md-3 col-6"><h6>{{ share.name }}:</h6><span class="price" v-if="share.checked">{{ sum * share.price }}</span><span class="price" v-else>0</span></div>'
        });

        var calc = new Vue({
          el: '#calc',
          data: {
            sum: 1,
            hours: 0,

            resolutions: [{
                name: '1080p',
                price: 10416,
                share_price: 0,
                checked: true
              },
              {
                name: '720p',
                price: 2604,
                share_price: 0,
                checked: true
              },
              {
                name: '480p',
                price: 625,
                share_price: 0,
                checked: false
              },
              {
                name: '360p',
                price: 166,
                share_price: 0,
                checked: true
              },
              {
                name: '240p',
                price: 37,
                share_price: 0,
                checked: false
              },
              {
                name: '144p',
                price: 15,
                share_price: 0,
                checked: false
              }
            ]
          },
          methods: {
            toggleChecked: function (c) {
              c.checked = !c.checked;
            },

            calculate: function () {
              var total = 0;

              this.resolutions.forEach(function (r) {
                if (r.checked) {
                  total += r.price;
                }
              });

              return total * Number(this.sum);
            },
          }
        });
      },
      finalize: function () {

      }
    },
    // Home page
    'home': {
      init: function () {
        $('.navbar-toggler').on('click', function () {
          $('#home_navigation').toggleClass('affix-toggler');
        });

        $(window).scroll(function () {
          if ($(document).scrollTop() > 50) {
            $('.navbar-left').addClass('affix-yellow');
          } else {
            $('.navbar-left').removeClass('affix-yellow');
          }

          if ($(document).scrollTop() > 50) {
            $('.navbar-right').addClass('affix-white');
          } else {
            $('.navbar-right').removeClass('affix-white');
          }
        });

        new Splide('.splide1', {
          autoplay: true,
          arrows: false,
          type: 'loop',
          pagination: true,
          perPage: 1,
          perMove: 1,
          breakpoints: {
            '640': {
              perPage: 1,
              gap: '1rem',
            },
            '480': {
              perPage: 1,
              gap: '1rem',
            },
          },
        }).mount();

        new Splide('.splide2', {
          autoplay: true,
          arrows: false,
          type: 'loop',
          pagination: true,
          perPage: 1,
          perMove: 1,
          breakpoints: {
            '640': {
              perPage: 1,
              gap: '1rem',
            },
            '480': {
              perPage: 1,
              gap: '1rem',
            },
          }
        }).mount();

        new Splide('.clients', {
          autoplay: true,
          type: 'loop',
          perPage: 5,
          perMove: 1,
          pagination: false,
          breakpoints: {
            '767': {
              perPage: 3,
              gap: '1rem',
              pagination: true,
              arrows: false
            },
            '640': {
              perPage: 2,
              gap: '1rem',
              pagination: true,
              arrows: false
            },
            '480': {
              perPage: 2,
              gap: '1rem',
              pagination: true,
              arrows: false,
            },
          }
        }).mount();
      },
      finalize: function () {

      }
    },
    'about': {
      init: function () {

        $(window).scroll(function () {
          if ($(document).scrollTop() > 50) {
            $('.navbar-left').addClass('affix-yellow');
          } else {
            $('.navbar-left').removeClass('affix-yellow');
          }

          if ($(document).scrollTop() > 50) {
            $('.navbar-right').addClass('affix-white');
          } else {
            $('.navbar-right').removeClass('affix-white');
          }
        });
      }
    },
    'services': {
      init: function () {

        $(window).scroll(function () {
          if ($(document).scrollTop() > 50) {
            $('.navbar-left').addClass('affix-yellow');
          } else {
            $('.navbar-left').removeClass('affix-yellow');
          }

          if ($(document).scrollTop() > 50) {
            $('.navbar-right').addClass('affix-white');
          } else {
            $('.navbar-right').removeClass('affix-white');
          }
        });

        new Splide('.plans', {
          autoplay: true,
          type: 'loop',
          perPage: 3,
          perMove: 1,
          pagination: true,
          arrows: true,
          breakpoints: {
            '1024': {
              perPage: 2,
              gap: '1rem',
              pagination: true,
              arrows: true
            },
            '992': {
              perPage: 2,
              gap: '1rem',
              pagination: true,
              arrows: true
            },
            '768': {
              perPage: 1,
              gap: '1rem',
              pagination: true,
              arrows: true
            },
            '640': {
              perPage: 1,
              gap: '1rem',
              pagination: true,
              arrows: true
            },
            '480': {
              perPage: 1,
              gap: '1rem',
              pagination: true,
              arrows: false
            },
          }
        }).mount();
      }
    },
    'contact': {
      init: function () {

        $(window).scroll(function () {
          if ($(document).scrollTop() > 50) {
            $('.navbar-left').addClass('affix-yellow');
          } else {
            $('.navbar-left').removeClass('affix-yellow');
          }

          if ($(document).scrollTop() > 50) {
            $('.navbar-right').addClass('affix-white');
          } else {
            $('.navbar-right').removeClass('affix-white');
          }
        });
      }
    },
    'blog': {
      init: function () {

      }
    }
  };

  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      UTIL.fire('common');

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      UTIL.fire('common', 'finalize');
    }
  };

  $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
